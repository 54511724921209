import { NoticeResponseDto } from '@zep/api';
import { zepAnalytics } from '@zep/utils/analytics';

export const NOTICE_EVENT_TYPES = {
  CLOSE: 'notice-close',
  CTA: 'notice-cta',
} as const;

export const NoticeLogger = {
  /**
   * 공지사항의 이벤트 코드를 가져옵니다
   */
  getEventCode: (notice: NoticeResponseDto) => {
    return notice.comment?.['eventCode'];
  },

  /**
   * 공지사항 닫기 이벤트를 기록합니다
   */
  onClickClose: (notice: NoticeResponseDto) => {
    const eventCode = NoticeLogger.getEventCode(notice);
    if (!eventCode) return;

    zepAnalytics.track(NOTICE_EVENT_TYPES.CLOSE, {
      eventCode,
      title: notice.content?.title,
    });
  },

  /**
   * 공지사항 CTA 버튼 클릭 이벤트를 기록합니다
   */
  onClickCta: (
    notice: NoticeResponseDto,
    buttonIndex: number,
    buttonText: string,
  ) => {
    const eventCode = NoticeLogger.getEventCode(notice);
    if (!eventCode) return;

    zepAnalytics.track(NOTICE_EVENT_TYPES.CTA, {
      eventCode,
      buttonIndex,
      buttonText,
      title: notice.content?.title,
    });
  },
};
