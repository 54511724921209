import { useState } from 'react';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { ClickTracking } from '@zep/components/ClickTracking';
import { useBodyAttribute } from '@zep/hooks';
import { GnbButtonType, HomeLogo } from '@zep/layout';
import { useUser } from '@zep/lib/auth';
import { Drawer, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from '@zep/ui';
import { cn } from '@zep/utils';
import { useRouter } from 'next/router';
import { MyQuizButton } from '../PageNavigatorButton';
import { EditNicknameMbButton, LogInButton, LogOutButton } from '../UserManagementButton';
import { DrawerButton } from './DrawerButton';
import { DrawerOverlay } from './DrawerOverlay';
import { TriggerContent } from './TriggerContent';
interface Props {
  logo?: React.ReactNode;
  buttons: GnbButtonType[];
  footerBottomAccessory?: React.ReactNode;
}
export const MobileDrawer = ({
  logo = <HomeLogo location={'header'} />,
  buttons,
  footerBottomAccessory
}: Props) => {
  const {
    user
  } = useUser();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isStickBannerShow = useBodyAttribute('data-is-stick-banner-show') === 'true';
  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };
  return <>
      {isOpen && <DrawerOverlay onClick={() => setIsOpen(false)} />}
      <Drawer open={isOpen} onOpenChange={setIsOpen} direction="top" modal={false} snapPoints={[]} fadeFromIndex={0} data-sentry-element="Drawer" data-sentry-source-file="MobileDrawer.tsx">
        <DrawerTrigger asChild data-sentry-element="DrawerTrigger" data-sentry-source-file="MobileDrawer.tsx">
          <TriggerContent onClick={() => setIsOpen(true)} data-sentry-element="TriggerContent" data-sentry-source-file="MobileDrawer.tsx" />
        </DrawerTrigger>
        <DrawerContent aria-describedby={undefined} className={cn(' bg-white z-40 inset-x-0 top-[64px] flex h-fit rounded-none shadow-[0_12px_16px_4px_#1c1b1f1f] focus:outline-none md:hidden', `${!isStickBannerShow && 'top-0'}`)} data-sentry-element="DrawerContent" data-sentry-source-file="MobileDrawer.tsx">
          <DrawerHeader className="flex items-center justify-between border-b-[1px_solid_#ededed] px-md py-sm" data-sentry-element="DrawerHeader" data-sentry-source-file="MobileDrawer.tsx">
            <VisuallyHidden.Root data-sentry-element="unknown" data-sentry-source-file="MobileDrawer.tsx">
              <DrawerTitle data-sentry-element="DrawerTitle" data-sentry-source-file="MobileDrawer.tsx" />
            </VisuallyHidden.Root>
            {logo}
            <DrawerClose className="cursor-pointer rounded-[8px] p-[8px] hover:bg-[#3E3E47]/[.04]" data-sentry-element="DrawerClose" data-sentry-source-file="MobileDrawer.tsx">
              <img src="/assets/icon_close_gray.svg" className="size-[24px]" alt="closeicon" />
            </DrawerClose>
          </DrawerHeader>
          <div className="flex flex-col gap-xs p-md">
            {buttons.map(button => <ClickTracking key={button.label} eventName={button.clickEventname} eventProperties={button.clickEventProperties}>
                <DrawerButton onClick={() => {
              router.push(button.href ?? '');
              toggleOpen();
            }}>
                  {button.label}
                </DrawerButton>
              </ClickTracking>)}
          </div>
          <HorizontalLine data-sentry-element="HorizontalLine" data-sentry-source-file="MobileDrawer.tsx" />
          <DrawerFooter className="mt-0 pt-0" data-sentry-element="DrawerFooter" data-sentry-source-file="MobileDrawer.tsx">
            {user ? <div className="flex flex-col gap-sm">
                <div className="flex items-center justify-between py-[20px]">
                  <EditNicknameMbButton />
                  <LogOutButton isDrawerOpen={isOpen} />
                </div>
                <MyQuizButton.Drawer onClick={toggleOpen} />
              </div> : <LogInButton type="drawer" className="hidden md:flex" />}
            {footerBottomAccessory}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>;
};
const HorizontalLine = () => {
  return <div className="my-sm h-4xs w-full bg-muted" data-sentry-component="HorizontalLine" data-sentry-source-file="MobileDrawer.tsx" />;
};
MobileDrawer.TriggerContent = TriggerContent;