import { EventProperties, zepAnalytics } from '@zep/utils/analytics';

export const trackGnbAnalytics = (
  to: string,
  location: string,
  eventProperties?: EventProperties,
) => {
  zepAnalytics.track(`enter_${to}`, {
    ...eventProperties,
    location,
  });
};
