export const Notice = () => <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Notice" data-sentry-source-file="Notice.tsx">
    <path d="M20.5 5C20.5 4.64353 20.3102 4.31401 20.0019 4.13509C19.6936 3.95617 19.3134 3.9549 19.0039 4.13176L12.2344 8H6.5C5.39543 8 4.5 8.89543 4.5 10V16C4.5 17.1046 5.39543 18 6.5 18H12.5L19.0039 21.8682C19.3134 22.0451 19.6936 22.0438 20.0019 21.8649C20.3102 21.686 20.5 21.3565 20.5 21V5Z" fill="url(#paint0_linear_9467_2895)" data-sentry-element="path" data-sentry-source-file="Notice.tsx" />
    <path d="M21.5 16.5555C22.7685 16.1588 23.7 14.8416 23.7 13.2778C23.7 11.714 22.7685 10.3968 21.5 9.99999V16.5555Z" fill="url(#paint1_linear_9467_2895)" data-sentry-element="path" data-sentry-source-file="Notice.tsx" />
    <path d="M6.5 19H12.5V23C12.5 23.5523 12.0523 24 11.5 24H7.5C6.94772 24 6.5 23.5523 6.5 23V19Z" fill="url(#paint2_linear_9467_2895)" data-sentry-element="path" data-sentry-source-file="Notice.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="Notice.tsx">
      <linearGradient id="paint0_linear_9467_2895" x1="14.1" y1="4" x2="14.1" y2="24" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Notice.tsx">
        <stop stopColor="#FFACB6" data-sentry-element="stop" data-sentry-source-file="Notice.tsx" />
        <stop offset="1" stopColor="#FF737B" data-sentry-element="stop" data-sentry-source-file="Notice.tsx" />
      </linearGradient>
      <linearGradient id="paint1_linear_9467_2895" x1="14.1" y1="4" x2="14.1" y2="24" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Notice.tsx">
        <stop stopColor="#FFACB6" data-sentry-element="stop" data-sentry-source-file="Notice.tsx" />
        <stop offset="1" stopColor="#FF737B" data-sentry-element="stop" data-sentry-source-file="Notice.tsx" />
      </linearGradient>
      <linearGradient id="paint2_linear_9467_2895" x1="14.1" y1="4" x2="14.1" y2="24" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Notice.tsx">
        <stop stopColor="#FFACB6" data-sentry-element="stop" data-sentry-source-file="Notice.tsx" />
        <stop offset="1" stopColor="#FF737B" data-sentry-element="stop" data-sentry-source-file="Notice.tsx" />
      </linearGradient>
    </defs>
  </svg>;