import { safeSessionStorage } from '@toss/storage';
import { v4 as uuidv4 } from 'uuid';

const ZEP_BROWSER_SESSION_ID_KEY = '@@ZEP_browser_session_id';

/**
 * 브라우저 세션 동안 유지되는 Session ID입니다.
 * Sentry, Server Log(X-ZEP-Session-Id) 등에 주입해서 유저의 행동을 추적할 때 사용합니다.
 * 브라우저 세션이 종료되면 자동으로 삭제됩니다.
 */
export function getBrowserSessionId() {
  const browserSessionId = safeSessionStorage.get(ZEP_BROWSER_SESSION_ID_KEY);

  if (browserSessionId == null) {
    const newSessionId = uuidv4();
    safeSessionStorage.set(ZEP_BROWSER_SESSION_ID_KEY, newSessionId);
    return newSessionId;
  }

  return browserSessionId;
}

/** 퀴즈에서 iframe으로 Play를 띄우는 경우, QueryParam을 사용해서 sessionId를 일관되게 관리합니다. */
export const BROWSER_SESSION_ID_QUERY_PARAM_KEY_FOR_IFRAME =
  'zepQuizBrowserSessionId';
