/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  GetNoticesParams,
  SuccessBodyListNoticeDoc,
  SuccessBodyListNoticeResponseDto,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAllNotices = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessBodyListNoticeDoc>(
    { url: `/notices`, method: 'GET', signal },
    options,
  );
};

export const getGetAllNoticesQueryKey = () => {
  return [`/notices`] as const;
};

export const getGetAllNoticesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllNotices>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAllNotices>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllNoticesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllNotices>>> = ({
    signal,
  }) => getAllNotices(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllNotices>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllNoticesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllNotices>>
>;
export type GetAllNoticesQueryError = ErrorType<unknown>;

export function useGetAllNotices<
  TData = Awaited<ReturnType<typeof getAllNotices>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAllNotices>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getAllNotices>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllNotices<
  TData = Awaited<ReturnType<typeof getAllNotices>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAllNotices>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getAllNotices>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllNotices<
  TData = Awaited<ReturnType<typeof getAllNotices>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAllNotices>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllNotices<
  TData = Awaited<ReturnType<typeof getAllNotices>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAllNotices>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllNoticesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetAllNoticesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllNotices>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getAllNotices>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllNoticesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllNotices>>> = ({
    signal,
  }) => getAllNotices(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getAllNotices>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllNoticesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllNotices>>
>;
export type GetAllNoticesSuspenseQueryError = ErrorType<unknown>;

export function useGetAllNoticesSuspense<
  TData = Awaited<ReturnType<typeof getAllNotices>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getAllNotices>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllNoticesSuspense<
  TData = Awaited<ReturnType<typeof getAllNotices>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getAllNotices>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllNoticesSuspense<
  TData = Awaited<ReturnType<typeof getAllNotices>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getAllNotices>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllNoticesSuspense<
  TData = Awaited<ReturnType<typeof getAllNotices>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getAllNotices>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllNoticesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getNotices = (
  params: GetNoticesParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessBodyListNoticeResponseDto>(
    { url: `/notices/location`, method: 'GET', params, signal },
    options,
  );
};

export const getGetNoticesQueryKey = (params: GetNoticesParams) => {
  return [`/notices/location`, ...(params ? [params] : [])] as const;
};

export const getGetNoticesQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotices>>,
  TError = ErrorType<unknown>,
>(
  params: GetNoticesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getNotices>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNoticesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotices>>> = ({
    signal,
  }) => getNotices(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNotices>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNoticesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNotices>>
>;
export type GetNoticesQueryError = ErrorType<unknown>;

export function useGetNotices<
  TData = Awaited<ReturnType<typeof getNotices>>,
  TError = ErrorType<unknown>,
>(
  params: GetNoticesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getNotices>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getNotices>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNotices<
  TData = Awaited<ReturnType<typeof getNotices>>,
  TError = ErrorType<unknown>,
>(
  params: GetNoticesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getNotices>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getNotices>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNotices<
  TData = Awaited<ReturnType<typeof getNotices>>,
  TError = ErrorType<unknown>,
>(
  params: GetNoticesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getNotices>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetNotices<
  TData = Awaited<ReturnType<typeof getNotices>>,
  TError = ErrorType<unknown>,
>(
  params: GetNoticesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getNotices>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetNoticesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetNoticesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotices>>,
  TError = ErrorType<unknown>,
>(
  params: GetNoticesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getNotices>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNoticesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotices>>> = ({
    signal,
  }) => getNotices(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getNotices>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNoticesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNotices>>
>;
export type GetNoticesSuspenseQueryError = ErrorType<unknown>;

export function useGetNoticesSuspense<
  TData = Awaited<ReturnType<typeof getNotices>>,
  TError = ErrorType<unknown>,
>(
  params: GetNoticesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getNotices>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNoticesSuspense<
  TData = Awaited<ReturnType<typeof getNotices>>,
  TError = ErrorType<unknown>,
>(
  params: GetNoticesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getNotices>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNoticesSuspense<
  TData = Awaited<ReturnType<typeof getNotices>>,
  TError = ErrorType<unknown>,
>(
  params: GetNoticesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getNotices>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetNoticesSuspense<
  TData = Awaited<ReturnType<typeof getNotices>>,
  TError = ErrorType<unknown>,
>(
  params: GetNoticesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getNotices>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetNoticesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
