import { ComponentPropsWithoutRef } from 'react';
import { NoSSR } from '@zep/components';
import { useLocale } from '@zep/hooks';
import { useUser } from '@zep/lib/auth';
import { Button } from '@zep/ui';
import { cn } from '@zep/utils';
import { zepAnalytics } from '@zep/utils/analytics';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { trackGnbAnalytics } from './trackGnbAnalytics';
export const EnterCodeButton = ({
  type
}: {
  type: 'drawer' | 'normal';
}) => {
  const {
    t
  } = useTranslation(['common']);
  const router = useRouter();
  const handleEnterCodeButtonClick = () => {
    router.push('/join');
    trackGnbAnalytics('code', 'landing');
    zepAnalytics.track('entercode_click');
  };
  const tEnterCode = t('gnb.header.enterCode', '코드로 입장');
  if (type === 'drawer') {
    return <Button className={'w-full'} onClick={handleEnterCodeButtonClick}>
        <img src="/assets/icon_enter_white.svg" alt="entericon" />
        {tEnterCode}
      </Button>;
  }
  return <Button size={'md'} intent={'alertNative'} onClick={handleEnterCodeButtonClick} data-sentry-element="Button" data-sentry-component="EnterCodeButton" data-sentry-source-file="PageNavigatorButton.tsx">
      {tEnterCode}
    </Button>;
};
export const _ExploreIconButton = () => {
  return <Button intent={'neutral'} transParented size={'sm'} asChild data-sentry-element="Button" data-sentry-component="_ExploreIconButton" data-sentry-source-file="PageNavigatorButton.tsx">
      <Link prefetch={false} href={'/public'} onClick={() => trackGnbAnalytics('public', 'Header')} data-sentry-element="Link" data-sentry-source-file="PageNavigatorButton.tsx">
        <img src="/assets/icon_magnifying_glass.svg" alt="search" />
      </Link>
    </Button>;
};
const _ExploreNormalButton = () => {
  const {
    t
  } = useTranslation(['common']);
  return <Button intent={'neutral'} asChild data-sentry-element="Button" data-sentry-component="_ExploreNormalButton" data-sentry-source-file="PageNavigatorButton.tsx">
      <Link prefetch={false} href={'/public'} onClick={() => trackGnbAnalytics('public', 'Header')} data-sentry-element="Link" data-sentry-source-file="PageNavigatorButton.tsx">
        {t('common.header.explore')}
      </Link>
    </Button>;
};
export const MyQuizButtonDrawer = (props: ComponentPropsWithoutRef<'button'>) => {
  const {
    t
  } = useTranslation(['common']);
  return <Button intent={'neutral'} className={'w-full'} asChild {...props} data-sentry-element="Button" data-sentry-component="MyQuizButtonDrawer" data-sentry-source-file="PageNavigatorButton.tsx">
      <Link prefetch={false} href={'/me/quiz'} onClick={() => trackGnbAnalytics('mylist', 'Header')} data-sentry-element="Link" data-sentry-source-file="PageNavigatorButton.tsx">
        {t('header.quizList.button')}
      </Link>
    </Button>;
};
export const _ExploreDrawerButton = (props: ComponentPropsWithoutRef<'button'>) => {
  const {
    t
  } = useTranslation(['common']);
  return <Button asChild intent={'neutral'} className={cn('p-0 w-full justify-start')} {...props} data-sentry-element="Button" data-sentry-component="_ExploreDrawerButton" data-sentry-source-file="PageNavigatorButton.tsx">
      <Link href={'/public'} onClick={() => trackGnbAnalytics('public', 'Header')} className="px-md py-[13.5px] text-[15px]" data-sentry-element="Link" data-sentry-source-file="PageNavigatorButton.tsx">
        {t('common.header.explore')}
      </Link>
    </Button>;
};
const MyQuizButtonNormal = () => {
  const {
    t
  } = useTranslation(['common']);
  return <Button asChild intent={'neutral'} className={cn('px-0 flex justify-start h-[36px] w-full  min-h-[36px]')} data-sentry-element="Button" data-sentry-component="MyQuizButtonNormal" data-sentry-source-file="PageNavigatorButton.tsx">
      <Link href={'/me/quiz'} className="flex items-center justify-start gap-3xs px-2xs py-[10px]" onClick={() => trackGnbAnalytics('mylist', 'Header')} data-sentry-element="Link" data-sentry-source-file="PageNavigatorButton.tsx">
        <img src="/assets/icon_paper.svg" alt="myquiz" />
        {t('header.quizList.button')}
      </Link>
    </Button>;
};
const UserGuideDrawerButton = () => {
  const {
    isShow,
    buttonText,
    goUserGuide
  } = useGnbUserGuideButtonInfo();
  if (!isShow) return null;
  return <Button className={cn('p-0 w-full justify-start')} onClick={goUserGuide} data-sentry-element="Button" data-sentry-component="UserGuideDrawerButton" data-sentry-source-file="PageNavigatorButton.tsx">
      <span className="px-md py-[13.5px] text-[15px]">{buttonText}</span>
    </Button>;
};
const UserGuideNormalButton = () => {
  const {
    isShow,
    buttonText,
    goUserGuide
  } = useGnbUserGuideButtonInfo();
  if (!isShow) return null;
  return <Button className={'hidden md:flex'} onClick={goUserGuide} data-sentry-element="Button" data-sentry-component="UserGuideNormalButton" data-sentry-source-file="PageNavigatorButton.tsx">
      {buttonText}
    </Button>;
};
const useGnbUserGuideButtonInfo = () => {
  const {
    t
  } = useTranslation(['common']);
  const {
    locale
  } = useLocale();
  const goUserGuide = () => window.open('https://docs.channel.io/zepquiz/ko');
  return {
    buttonText: t('common.header.userGuide', '이용 가이드'),
    goUserGuide,
    isShow: locale === 'ko'
  };
};
export const HeaderAdminButton = () => {
  const router = useRouter();
  const {
    isAuthenticated,
    user
  } = useUser();
  return <NoSSR data-sentry-element="NoSSR" data-sentry-component="HeaderAdminButton" data-sentry-source-file="PageNavigatorButton.tsx">
      {isAuthenticated && user?.admin && <Button className={cn('p-0 w-fit hidden md:flex')} onClick={() => router.push('/admin')}>
          <span className="px-[8.5px] py-sm">{'어드민'}</span>
        </Button>}
    </NoSSR>;
};
const _ExploreButton = () => <></>;
const _UserGuideButton = () => <></>;
const _MyQuizButton = () => <></>;
export const ExploreButton = Object.assign(_ExploreButton, {
  Drawer: _ExploreDrawerButton,
  Normal: _ExploreNormalButton,
  Icon: _ExploreIconButton
});
export const UserGuideButton = Object.assign(_UserGuideButton, {
  Drawer: UserGuideDrawerButton,
  Normal: UserGuideNormalButton
});
export const MyQuizButton = Object.assign(_MyQuizButton, {
  Drawer: MyQuizButtonDrawer,
  Normal: MyQuizButtonNormal
});