import type { SVGProps } from 'react';

/** 2xs: 14px, xs: 16px, sm: 20px, md:24px, lg:28px, xl: 32px **/
export type IconSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
interface Props extends SVGProps<SVGSVGElement> {
  size?: IconSize;
}
const getDimension = (size: IconSize) => {
  switch (size) {
    case '2xs':
      return 14;
    case 'xs':
      return 16;
    case 'sm':
      return 20;
    case 'md':
      return 24;
    case 'lg':
      return 28;
    case 'xl':
      return 32;
  }
};
const ZepIcon = (IconComponent: (props: SVGProps<SVGSVGElement>) => React.JSX.Element) => {
  const Icon = (props: Props) => <IconComponent {...props} width={props.size ? getDimension(props.size) : props.width ?? 24} height={props.size ? getDimension(props.size) : props.height ?? 24} data-sentry-element="IconComponent" data-sentry-component="Icon" data-sentry-source-file="ZepIcon.tsx" />;
  return Icon;
};
export default ZepIcon;