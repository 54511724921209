import React, { CSSProperties, ReactNode, useState } from 'react';
import { useViewportDevice } from '@zep/hooks';
import { TooltipArrow, TooltipContent, TooltipProvider, TooltipRoot, TooltipTrigger } from '@zep/ui';
import { cn } from '@zep/utils';
type TooltipProps = {
  side?: 'bottom' | 'top' | 'right' | 'left' | undefined;
  align?: 'center' | 'start' | 'end' | undefined;
  trigger: ReactNode;
  content: ReactNode;
  className?: string;
  contentClassName?: string;
  contentStyle?: CSSProperties;
  delayDuration?: number;
  useOnClick?: boolean;
  arrow?: {
    hide?: boolean;
    fill?: string;
  };
};
export const Tooltip = (props: TooltipProps) => {
  const {
    side = 'bottom',
    align = 'center',
    trigger,
    content,
    className,
    arrow,
    delayDuration = 80,
    useOnClick = true
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const {
    isMobile
  } = useViewportDevice();
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(prev => !prev);
  };
  return <TooltipProvider delayDuration={delayDuration} data-sentry-element="TooltipProvider" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
      <TooltipRoot onOpenChange={setIsOpen} open={useOnClick && isMobile ? isOpen : undefined} data-sentry-element="TooltipRoot" data-sentry-source-file="Tooltip.tsx">
        <TooltipTrigger
      // 모바일에선 툴팁이 클릭으로 나와야 함
      onClick={e => isMobile ? handleClick(e) : e.stopPropagation()} asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="Tooltip.tsx">
          {trigger}
        </TooltipTrigger>
        <TooltipContent side={side} align={align} className={cn('border-none rounded-[4px] bg-[rgba(28,_27,_31,_0.80)] p-[6px] text-body-sm font-semibold text-white', className)} style={{
        ...props.contentStyle
      }} data-sentry-element="TooltipContent" data-sentry-source-file="Tooltip.tsx">
          {!arrow?.hide && <TooltipArrow width={10} height={6} style={{
          fill: arrow?.fill ? arrow.fill : 'rgba(28, 27, 31, 0.80)'
        }} />}

          {content}
        </TooltipContent>
      </TooltipRoot>
    </TooltipProvider>;
};