import { ERROR_MESSAGES } from '@zep/consts/error';

export const IGNORED_ERROR_PATTERNS: Record<string, (string | RegExp)[]> = {
  QUIZ_PLAY: [
    /** https://zep-us.sentry.io/issues/6192959312
     *  웨이스페이스 아이프레임 이슈, TODO:: 웨일브라우저인지 체크를 추가로 차후 해야합니다!!
     */
    'Blocked a frame with origin',
  ],
  COMMON: [
    // https://zep-us.sentry.io/issues/6192800547/
    // 의도한 에러(브라우저 뒤로 가기 막기에러)
    ERROR_MESSAGES.routeChangeAborted,
    /**
     * https://zep-us.sentry.io/discover/results/?dataset=errors&field=title&field=project&field=user.display&field=timestamp&name=All%20Errors&project=4508516864688128&query=gmo&queryDataset=error-events&sort=-timestamp&statsPeriod=1h&yAxis=count%28%29
     * https://stackoverflow.com/questions/79434372/sentry-errors-variable-not-found-gmo-invalid-or-unexpected-token-due-to-go
     * 구글 브라우저 오류
     */
    "Can't find variable: gmo",
    // https://zep-us.sentry.io/share/issue/af35bb21a91c4e0484c1d009811542ee/
    // 모바일 사파리 이슈
    /Object\.prototype\.hasOwnProperty\.call\(.+,"telephone"\)/,
    // 모바일에서 shadcn ui의 HoverCardTrigger 클릭 시 에러가 나는데 동작엔 이상이 없기에 무시합니다.
    'Unable to preventDefault inside passive event listener invocation',
  ],
} as const;

export const shouldIgnoreError = (error: unknown) => {
  if (isErrorWithMessage(error)) {
    return Object.values(IGNORED_ERROR_PATTERNS)
      .flat()
      .some(pattern => {
        if (typeof pattern === 'string') {
          return error.message.includes(pattern);
        } else if (pattern instanceof RegExp) {
          return pattern.test(error.message);
        }
        return false;
      });
  }
  return false;
};

export const isErrorWithMessage = (
  error: unknown,
): error is { message: string } =>
  !!error &&
  typeof error === 'object' &&
  'message' in error &&
  typeof error.message === 'string';
