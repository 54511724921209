export const FAQ = () => <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="FAQ" data-sentry-source-file="FAQ.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.2917 4C8.60774 4 4 8.60774 4 14.2917C4 19.9756 8.60774 24.5833 14.2917 24.5833C15.7127 24.5833 17.0688 24.2947 18.3026 23.7722L22.1018 24.7922C23.4438 25.1525 24.5896 23.7716 23.988 22.5192L22.8106 20.0679C23.9292 18.4209 24.5833 16.4315 24.5833 14.2917C24.5833 8.60774 19.9756 4 14.2917 4ZM14.4332 10.3537C13.4656 10.3076 12.6542 10.8482 12.5086 11.7217C12.4168 12.2725 11.8958 12.6446 11.345 12.5528C10.7942 12.461 10.4221 11.9401 10.5139 11.3893C10.8739 9.2296 12.843 8.2535 14.5294 8.33381C15.3933 8.37494 16.2811 8.68501 16.9647 9.32381C17.6654 9.97848 18.0833 10.9163 18.0833 12.0611C18.0833 13.3667 17.5696 14.3349 16.6852 14.9245C16.1834 15.259 15.6116 15.438 15.0501 15.5259V15.5999C15.0501 16.1583 14.5974 16.611 14.039 16.611C13.4806 16.611 13.0279 16.1583 13.0279 15.5999V14.5888C13.0279 14.0304 13.4806 13.5777 14.039 13.5777C14.7169 13.5777 15.2381 13.4589 15.5635 13.242C15.8166 13.0732 16.0612 12.7776 16.0612 12.0611C16.0612 11.4364 15.8472 11.0472 15.5841 10.8013C15.304 10.5396 14.8964 10.3758 14.4332 10.3537ZM14.039 17.6221C13.2014 17.6221 12.5223 18.3011 12.5223 19.1387C12.5223 19.9763 13.2014 20.6553 14.039 20.6553C14.8766 20.6553 15.5556 19.9763 15.5556 19.1387C15.5556 18.3011 14.8766 17.6221 14.039 17.6221Z" fill="url(#paint0_linear_9467_2921)" data-sentry-element="path" data-sentry-source-file="FAQ.tsx" />
    <path d="M12.5086 11.7217C12.6542 10.8482 13.4656 10.3076 14.4332 10.3537C14.8964 10.3758 15.304 10.5396 15.5841 10.8013C15.8472 11.0472 16.0612 11.4364 16.0612 12.0611C16.0612 12.7776 15.8166 13.0732 15.5635 13.242C15.2381 13.4589 14.7169 13.5777 14.039 13.5777C13.4806 13.5777 13.0279 14.0304 13.0279 14.5888V15.5999C13.0279 16.1583 13.4806 16.611 14.039 16.611C14.5974 16.611 15.0501 16.1583 15.0501 15.5999V15.5259C15.6116 15.438 16.1834 15.259 16.6852 14.9245C17.5696 14.3349 18.0833 13.3667 18.0833 12.0611C18.0833 10.9163 17.6654 9.97848 16.9647 9.32381C16.2811 8.68501 15.3933 8.37494 14.5294 8.33381C12.843 8.2535 10.8739 9.2296 10.5139 11.3893C10.4221 11.9401 10.7942 12.461 11.345 12.5528C11.8958 12.6446 12.4168 12.2725 12.5086 11.7217Z" fill="white" data-sentry-element="path" data-sentry-source-file="FAQ.tsx" />
    <path d="M12.5223 19.1387C12.5223 18.3011 13.2014 17.6221 14.039 17.6221C14.8766 17.6221 15.5556 18.3011 15.5556 19.1387C15.5556 19.9763 14.8766 20.6553 14.039 20.6553C13.2014 20.6553 12.5223 19.9763 12.5223 19.1387Z" fill="white" data-sentry-element="path" data-sentry-source-file="FAQ.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="FAQ.tsx">
      <linearGradient id="paint0_linear_9467_2921" x1="14.2917" y1="4" x2="14.2917" y2="24.8504" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="FAQ.tsx">
        <stop stopColor="#9ED4FF" data-sentry-element="stop" data-sentry-source-file="FAQ.tsx" />
        <stop offset="1" stopColor="#3DA9FF" data-sentry-element="stop" data-sentry-source-file="FAQ.tsx" />
      </linearGradient>
    </defs>
  </svg>;