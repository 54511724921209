import { zettaClient } from '../analytics/zettaClient';
import { getApplicationSessionId, getBrowserSessionId } from '../common';
import { LogstashLogger } from '../logger';
import { ZEPLogger } from '../logger/ZEPLogger';

import { ZettaLogger } from './zettaLogger';

/**
 * Zetta, Logstash(Kibana), console 등 다양한 형태의 로깅을 쉽게 할 수 있는 Logger
 *
 * Node.js 환경을 지원하지 않습니다
 */
export const zepLogger = new ZEPLogger({
  loggers: [new LogstashLogger(), new ZettaLogger(zettaClient)],
  initialState: {
    zep_service_name: 'zep-quiz',
    zep_deployment_env: process.env.NEXT_PUBLIC_STAGE,
    zepBrowserSessionId: getBrowserSessionId(),
    zepApplicationSessionId: getApplicationSessionId(),
  },
});
