import { createQuery } from 'react-query-kit';
import {
  getQuizPlanOptions,
  type SuccessBodyGetQuizPlanOptionsResponse,
} from '@zep/api';
export const UseSubscribeUserPlanKey = ['subscribe-user-plan'];

export const useSubscribeUserPlan = createQuery<
  SuccessBodyGetQuizPlanOptionsResponse,
  void
>({
  queryKey: UseSubscribeUserPlanKey,
  fetcher: async () => {
    return getQuizPlanOptions();
  },
  // 실질적으로 영향을주면 안되서 false 로 설정
  meta: { ignoreError: true },
  throwOnError: false,
  enabled: false,
  refetchOnMount: 'always',
  refetchOnWindowFocus: 'always',
});
