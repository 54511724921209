import { forwardRef, useState } from 'react';
import { isMobileWeb } from '@toss/utils';
import { ClickTracking } from '@zep/components/ClickTracking';
import { ChevronDownIcon } from '@zep/icons';
import { Button } from '@zep/ui_v3';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@zep/ui_v3/components/hover-card';
import { cn } from '@zep/utils';
import Link from 'next/link';
import { GnbButtonType, GnbSubButtonType } from '..';
export const GnbButton = ({
  button
}: {
  button: GnbButtonType;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = isMobileWeb();
  if (button.href) {
    return <Link href={button.href} target={button.target} prefetch={false}>
        <GnbMainButton button={button} />
      </Link>;
  }
  return <HoverCard openDelay={50} closeDelay={50} open={isMobile ? isOpen : undefined} onOpenChange={setIsOpen} data-sentry-element="HoverCard" data-sentry-component="GnbButton" data-sentry-source-file="GnbButton.tsx">
      <HoverCardTrigger onClick={() => {
      if (isMobile) {
        setIsOpen(prev => !prev);
      }
    }} asChild data-sentry-element="HoverCardTrigger" data-sentry-source-file="GnbButton.tsx">
        <GnbMainButton button={button} isOpen={isOpen} data-sentry-element="GnbMainButton" data-sentry-source-file="GnbButton.tsx" />
      </HoverCardTrigger>
      <HoverCardContent align="start" className="flex flex-col rounded-xs border-assistive p-2xs" data-sentry-element="HoverCardContent" data-sentry-source-file="GnbButton.tsx">
        {button.subButtons?.map(subButton => <Link key={subButton.label} href={subButton.href} target={subButton.target} prefetch={false}>
            <GnbSubButton button={subButton} />
          </Link>)}
      </HoverCardContent>
    </HoverCard>;
};
const GnbMainButton = forwardRef<HTMLButtonElement, {
  button: GnbButtonType;
  isOpen?: boolean;
}>(({
  button,
  isOpen,
  ...rest
}, ref) => {
  const hasSubButtons = 0 < (button.subButtons?.length ?? 0);
  return <ClickTracking eventName={button.clickEventname} eventProperties={button.clickEventProperties}>
      <Button ref={ref} {...rest} variant="transparent" intent="neutral" size="sm" rightSlot={!hasSubButtons ? undefined : <ChevronDownIcon className={cn('transition-transform ease duration-50', isOpen && 'rotate-180')} size="xs" />}>
        {button.label}
      </Button>
    </ClickTracking>;
});
GnbMainButton.displayName = 'GnbMainButton';
const GnbSubButton = ({
  button
}: {
  button: GnbSubButtonType;
}) => {
  return <ClickTracking eventName={button.clickEventname} eventProperties={button.clickEventProperties} data-sentry-element="ClickTracking" data-sentry-component="GnbSubButton" data-sentry-source-file="GnbButton.tsx">
      <button className="flex w-full min-w-[188px] items-center gap-xs rounded-xs p-xs text-body-md-medium hover:bg-gray-50">
        {button.icon}
        {button.label}
      </button>
    </ClickTracking>;
};