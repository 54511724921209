import { useIsMounted } from '@toss/react';
import { getCookie } from 'cookies-next';

export const useCountryCode = () => {
  const isMounted = useIsMounted();
  /**
   *  로컬에서 작업시 해당 값을 바꿔서 테스트 하시면 됩니다. 해당 국가코드는 미들웨서 엣지 환경에서만 제공됩니다
   * */
  const countryCode = getCookie('countryCode') || '';

  return {
    countryCode,
    // onlyClient
    isInitialized: isMounted,
    // 한국 코드는 많이 사용할꺼같아 변수로 제공
    isKR: countryCode?.toUpperCase() === 'KR',
  };
};
