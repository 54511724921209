import { ComponentPropsWithoutRef, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import { SwitchCase } from '@toss/react';
import { NoticeResponseDto } from '@zep/api';
import { useViewportDevice } from '@zep/hooks';
import { XIcon } from '@zep/icons';
import { IconSize } from '@zep/icons/ZepIcon';
import { ICON_COLOR } from '@zep/ui/ui-tokens/color';
import { Button, ButtonProps } from '@zep/ui_v3';
import { cn } from '@zep/utils';
import { NoticeLogger } from './NoticeLogger';
import { NoticeManager } from './NoticeStorage';
export const PopupNotice = (props: NoticeResponseDto) => {
  const [isActive, setActive] = useState(true);
  const {
    content
  } = props;
  const title = content?.title || '';
  const subText = content?.subText || '';
  const image = content?.imageUrl || '';
  const {
    isMobile
  } = useViewportDevice();
  const renderType = (() => {
    if (isMobile) {
      return 'mobile';
    }
    return image ? 'withImage' : 'withoutImage';
  })();
  const onClose = () => {
    setActive(false);
    NoticeLogger.onClickClose(props);
    NoticeManager.setNotice(props.id || '', 'MODAL');
  };
  if (!isActive) return null;
  return createPortal(<SwitchCase value={renderType} caseBy={{
    mobile: <Container className={'bg-default'} location={props.location}>
            <CloseIconButton size={'sm'} iconSize={'md'} className={cn('p-0', 'absolute right-[-12px] top-[-16px] w-[36px] ', 'shadow-[rgba(28,27,31,0.12)_0px_1px_4px,_rgba(28,27,31,0.04)_0px_0px_6px]')} onClick={onClose} />
            <div className={'flex flex-col gap-md'}>
              <div className={'flex justify-between gap-md'}>
                <div className={'flex flex-col gap-2xs'}>
                  <Title className={'gap-md'}>{title}</Title>
                  <Content>{subText}</Content>
                </div>
                {image && <img className={'size-[58px] rounded-sm object-cover object-center'} alt={'notice-img'} src={image} />}
              </div>
              <Buttons {...props} />
            </div>
          </Container>,
    withImage: <Container location={props.location} className={'p-0'}>
            <div className={'self-stretch rounded-t-md'}>
              <img className={'shrink-0 rounded-t-md'} src={image} alt="notice-img" />
              <CloseIconButton className={'absolute right-[9px] top-[9px] size-[26px] p-3xs'} iconSize={'xs'} onClick={onClose} />
            </div>
            <div className={'flex flex-col gap-[14px] rounded-b-md bg-default p-md'}>
              <div className={'flex flex-col gap-2xs'}>
                <Title>{title}</Title>
                <Content>{subText}</Content>
              </div>
              <Buttons {...props} />
            </div>
          </Container>,
    withoutImage: <Container className={'bg-default'} location={props.location}>
            <div className={'flex flex-col gap-[14px]'}>
              <div className={'flex flex-col gap-2xs'}>
                <Title rightSlot={<CloseIconButton className={'size-[26px] p-3xs'} onClick={onClose} />}>
                  {title}
                </Title>
                <Content>{subText}</Content>
              </div>
              <Buttons {...props} />
            </div>
          </Container>
  }} />, document.body);
};
const Container = (props: ComponentPropsWithoutRef<'div'> & Pick<NoticeResponseDto, 'location'>) => {
  const {
    isMobile
  } = useViewportDevice();
  const {
    className,
    location,
    ...rest
  } = props;
  return <div className={cn(['animate-once animate-duration-500 animate-delay-[400ms] animate-ease-in ', isMobile ? 'transform -translate-x-1/2' : 'animate-fade-up', isMobile ? 'w-[calc(100vw-32px)] py-md px-lg' : 'w-[320px] p-md', isMobile ? 'bottom-[25px] left-1/2' : location === 'PLAY' ? 'right-md bottom-lg' : 'right-md bottom-[90px]', 'fixed rounded-md  border-opacity border flex flex-col', 'z-notice', 'shadow-[0px_8px_20px_0px_rgba(28,_27,_31,_0.12),_0px_0px_16px_0px_rgba(28,_27,_31,_0.08)]', className])} {...rest} data-sentry-component="Container" data-sentry-source-file="PopupNotice.tsx" />;
};
const Buttons = (props: NoticeResponseDto) => {
  const buttons = props.content?.buttons.filter(d => !!d.text && !!d.link)
  // 정렬 반대로 해야함(메인이 우측으로 가야해서 변경됨)
  .reverse() || [];
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, index: number, buttonText: string) => {
    e.preventDefault();
    const link = buttons[index].link;
    if (!link) return;
    window.open(link, '_blank');
    NoticeLogger.onClickCta(props, index + 1, buttonText);
  };
  return <div className={'flex gap-2xs'} data-sentry-component="Buttons" data-sentry-source-file="PopupNotice.tsx">
      {buttons.map((button, index, all) => <Button key={index} title={button.text} className={'w-full'} intent={all.length === 2 && index === 0 ? 'neutral' : 'alternative'} size={'md'} onClick={event => handleClick(event, index, button.text)}>
          {button.text}
        </Button>)}
    </div>;
};
const Title = (props: ComponentPropsWithoutRef<'h3'> & {
  rightSlot?: ReactNode;
}) => {
  const {
    rightSlot,
    children,
    className,
    ...rest
  } = props;
  return <div className={cn('flex justify-between  gap-3xs text-body-lg-bold text-default', className)} {...rest} data-sentry-component="Title" data-sentry-source-file="PopupNotice.tsx">
      <span>{children}</span>
      {rightSlot}
    </div>;
};
const Content = (props: ComponentPropsWithoutRef<'div'>) => {
  return <div className={'text-body-sm-medium text-neutral'} {...props} data-sentry-component="Content" data-sentry-source-file="PopupNotice.tsx" />;
};
const CloseIconButton = (props: Pick<ButtonProps, 'intent' | 'size' | 'className' | 'onClick'> & {
  iconSize?: IconSize;
}) => {
  const {
    iconSize,
    ..._props
  } = props;
  return <Button intent={props.intent || 'neutral'} rounded={'full'} size={'2xs'} variant={'contained'} leftSlot={<XIcon fill={ICON_COLOR.neutral} size={iconSize} />} {..._props} data-sentry-element="Button" data-sentry-component="CloseIconButton" data-sentry-source-file="PopupNotice.tsx" />;
};