import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { usePrevious } from '@toss/react';
import { NoSSR } from '@zep/components';
import { useUser } from '@zep/lib/auth';
import { useSubscribeUserPlan, UseSubscribeUserPlanKey } from '@zep/lib/auth';
import { useRouter } from 'next/router';
const DISABLED_PATHNAME_LIST = ['sign-up', 'login', 'admin', 'auth', 'play', 'trouble', 'build'];
export const SubscribeUserPlan = () => {
  const {
    isInitialization,
    user,
    updateUser
  } = useUser();
  const router = useRouter();
  const client = useQueryClient();
  const isDisabledPage = useMemo(() => {
    return DISABLED_PATHNAME_LIST.includes(router.pathname.split('/')?.[1]);
  }, [router.pathname]);
  const enabled = useMemo<boolean>(() => {
    if (isDisabledPage) {
      return false;
    }
    if (!isInitialization) return false;
    return user !== null;
  }, [isDisabledPage, isInitialization, user]);
  const previousEnabled = usePrevious(enabled);
  const {
    data: planData,
    isSuccess,
    isError,
    refetch
  } = useSubscribeUserPlan({
    enabled: enabled
  });
  useEffect(() => {
    if (isSuccess) {
      const planDuration = planData.data?.startDate ? {
        startDate: planData?.data?.startDate || '',
        endDate: planData?.data?.endDate || ''
      } : undefined;
      updateUser({
        planType: planData?.data?.quizPlanType || 'FREE',
        planDuration
      });
    }
  }, [isSuccess, planData, updateUser]);
  useEffect(() => {
    if (isError) {
      updateUser({
        planType: 'FREE',
        planDuration: undefined
      });
    }
  }, [isError, updateUser]);
  useEffect(() => {
    const handleRouteChangeComplete = () => {
      if (!enabled) return;
      refetch();
    };
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [enabled, refetch, router.events]);
  useEffect(() => {
    // 기존 활성화 되어잇엇는데, 현재는 비활성화 된 경우 쿼리 리셋을 시킨다
    // 보통 로그인 후 로그아웃 된 케이스
    if (previousEnabled && !enabled) {
      client.resetQueries({
        queryKey: UseSubscribeUserPlanKey
      });
    }
  }, [client, enabled, previousEnabled]);
  return null;
};
export const SubscribeUserPlanContainer = () => {
  return <NoSSR data-sentry-element="NoSSR" data-sentry-component="SubscribeUserPlanContainer" data-sentry-source-file="SubscribeUserPlan.tsx">
      <SubscribeUserPlan data-sentry-element="SubscribeUserPlan" data-sentry-source-file="SubscribeUserPlan.tsx" />
    </NoSSR>;
};