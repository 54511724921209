/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  GetAllMyQuizzesParams,
  SearchResponseListSearchMyQuizResV1,
  SetQuizUserInfoReq,
  SuccessBodyAuthRes,
  SuccessBodySetQuizUserTypeResponse,
  UpdateQuizUserNameReq,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAllMyQuizzes = (
  params?: GetAllMyQuizzesParams,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SearchResponseListSearchMyQuizResV1>(
    { url: `/users/myquiz`, method: 'POST', params },
    options,
  );
};

export const getGetAllMyQuizzesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllMyQuizzes>>,
    TError,
    { params?: GetAllMyQuizzesParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getAllMyQuizzes>>,
  TError,
  { params?: GetAllMyQuizzesParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getAllMyQuizzes>>,
    { params?: GetAllMyQuizzesParams }
  > = props => {
    const { params } = props ?? {};

    return getAllMyQuizzes(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetAllMyQuizzesMutationResult = NonNullable<
  Awaited<ReturnType<typeof getAllMyQuizzes>>
>;

export type GetAllMyQuizzesMutationError = ErrorType<unknown>;

export const useGetAllMyQuizzes = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllMyQuizzes>>,
    TError,
    { params?: GetAllMyQuizzesParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getAllMyQuizzes>>,
  TError,
  { params?: GetAllMyQuizzesParams },
  TContext
> => {
  const mutationOptions = getGetAllMyQuizzesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const setQuizUserInfo = (
  setQuizUserInfoReq: BodyType<SetQuizUserInfoReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodySetQuizUserTypeResponse>(
    {
      url: `/users/info`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: setQuizUserInfoReq,
    },
    options,
  );
};

export const getSetQuizUserInfoMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setQuizUserInfo>>,
    TError,
    { data: BodyType<SetQuizUserInfoReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setQuizUserInfo>>,
  TError,
  { data: BodyType<SetQuizUserInfoReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setQuizUserInfo>>,
    { data: BodyType<SetQuizUserInfoReq> }
  > = props => {
    const { data } = props ?? {};

    return setQuizUserInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetQuizUserInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof setQuizUserInfo>>
>;
export type SetQuizUserInfoMutationBody = BodyType<SetQuizUserInfoReq>;
export type SetQuizUserInfoMutationError = ErrorType<unknown>;

export const useSetQuizUserInfo = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setQuizUserInfo>>,
    TError,
    { data: BodyType<SetQuizUserInfoReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setQuizUserInfo>>,
  TError,
  { data: BodyType<SetQuizUserInfoReq> },
  TContext
> => {
  const mutationOptions = getSetQuizUserInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const editQuizUserName = (
  updateQuizUserNameReq: BodyType<UpdateQuizUserNameReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyAuthRes>(
    {
      url: `/users/edit`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateQuizUserNameReq,
    },
    options,
  );
};

export const getEditQuizUserNameMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editQuizUserName>>,
    TError,
    { data: BodyType<UpdateQuizUserNameReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editQuizUserName>>,
  TError,
  { data: BodyType<UpdateQuizUserNameReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editQuizUserName>>,
    { data: BodyType<UpdateQuizUserNameReq> }
  > = props => {
    const { data } = props ?? {};

    return editQuizUserName(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditQuizUserNameMutationResult = NonNullable<
  Awaited<ReturnType<typeof editQuizUserName>>
>;
export type EditQuizUserNameMutationBody = BodyType<UpdateQuizUserNameReq>;
export type EditQuizUserNameMutationError = ErrorType<unknown>;

export const useEditQuizUserName = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editQuizUserName>>,
    TError,
    { data: BodyType<UpdateQuizUserNameReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof editQuizUserName>>,
  TError,
  { data: BodyType<UpdateQuizUserNameReq> },
  TContext
> => {
  const mutationOptions = getEditQuizUserNameMutationOptions(options);

  return useMutation(mutationOptions);
};
