import { useEffect } from 'react';
import { useIsMounted } from '@toss/react';
import { useIsInGoogleClassroom } from '@zep/shared/googleClassroom';
import { sendPageView, zepAnalytics } from '@zep/utils/analytics';
import { useRouter } from 'next/router';
export const usePageViewLogging = () => {
  const router = useRouter();
  const isMounted = useIsMounted();
  const isInGoogleClassroom = useIsInGoogleClassroom();
  useEffect(() => {
    if (router.isReady && isMounted) {
      zepAnalytics.track('page_view', {
        location: router.pathname,
        googleClassroom: isInGoogleClassroom
      });
    }
  }, [router.isReady, isMounted, router.pathname]);
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      sendPageView(url);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);
};