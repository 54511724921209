import { useMemo } from 'react';
import { useIsMounted } from '@toss/react';

import { googleClassroomContextManager } from '../GoogleClassroomContextManager';
import { UserType } from '../types';

const STORAGE_KEY = 'IS_IN_GOOGLE_CLASSROOM_FOR_UI_DEBUG';

export function useIsInGoogleClassroom() {
  const isMounted = useIsMounted();

  return useMemo(() => {
    if (!isMounted) {
      return false;
    }

    /**
     * 구글 클래스룸 기능은 구글 클래스룸 사이트 내의 iframe에서만 사용할 수 있습니다.
     * 그런데 구글 클래스룸 사이트에서는 라이브(quiz.zep.us) 환경의 젭퀴즈만 접근할 수 있어서 개발 중에 테스트하기가 무척 까다롭습니다.
     * 구글 클래스룸 안/밖일 때 UI 분기를 해둔 경우가 많은데요. 구글 클래스룸 기능 자체를 테스트할 일은 별로 없지만, UI 분기는 테스트할 일이 꽤 자주 있습니다.
     * 구글 클래스룸 사이트 밖에서는 구글 클래스룸 API를 사용할 수 없지만, UI 분기라도 쉽게 테스트할 수 있도록 디버그용 플래그를 사용합니다.
     * 주의: 이 값을 true로 설정한다고 해서 구글 클래스룸 기능(e.g. 과제 생성)을 사용할 수 있는 건 아닙니다. UI 테스트만 가능합니다.
     */
    if (localStorage.getItem(STORAGE_KEY) === 'true') {
      return true;
    }

    const context = googleClassroomContextManager.getLandingPageContext();
    if (context == null) {
      return false;
    }

    switch (context.userType) {
      case UserType.Student:
        return true;
      case UserType.Teacher:
        return true;
      case UserType.Unknown:
        return false;
    }
  }, [isMounted]);
}
