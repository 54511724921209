export const UserGuide = () => <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="UserGuide" data-sentry-source-file="UserGuide.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M24.5833 14.2917C24.5833 8.60774 19.9756 4 14.2917 4C8.60774 4 4 8.60774 4 14.2917C4 19.9756 8.60774 24.5833 14.2917 24.5833C15.7127 24.5833 17.0688 24.2947 18.3026 23.7722L22.1018 24.7922C23.4438 25.1525 24.5896 23.7716 23.988 22.5192L22.8106 20.0679C23.9292 18.4209 24.5833 16.4315 24.5833 14.2917Z" fill="url(#paint0_linear_9467_2912)" data-sentry-element="path" data-sentry-source-file="UserGuide.tsx" />
    <path d="M14.2917 14.8291C13.6934 14.8291 13.2083 14.3441 13.2083 13.7458V10.4958C13.2083 9.89746 13.6934 9.41244 14.2917 9.41244C14.89 9.41244 15.375 9.89746 15.375 10.4958V13.7458C15.375 14.3441 14.89 14.8291 14.2917 14.8291Z" fill="white" data-sentry-element="path" data-sentry-source-file="UserGuide.tsx" />
    <path d="M12.6667 17.5374C12.6667 16.64 13.3942 15.9124 14.2917 15.9124C15.1891 15.9124 15.9167 16.64 15.9167 17.5374C15.9167 18.4349 15.1891 19.1624 14.2917 19.1624C13.3942 19.1624 12.6667 18.4349 12.6667 17.5374Z" fill="white" data-sentry-element="path" data-sentry-source-file="UserGuide.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="UserGuide.tsx">
      <linearGradient id="paint0_linear_9467_2912" x1="14.2917" y1="4" x2="14.2917" y2="24.8504" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="UserGuide.tsx">
        <stop stopColor="#DABCFF" data-sentry-element="stop" data-sentry-source-file="UserGuide.tsx" />
        <stop offset="1" stopColor="#AF70FF" data-sentry-element="stop" data-sentry-source-file="UserGuide.tsx" />
      </linearGradient>
    </defs>
  </svg>;