import { SUPPORTED_LOCALES_OBJ } from '@zep/consts/localize';
import { Namespace } from '@zep/types/translation';
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export const THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS =
  'THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS';

type StaticProps = Pick<GetStaticPropsContext, 'locale'> & {
  namespaces?: Namespace[];
};

type ServerSideProps = Pick<GetServerSidePropsContext, 'locale'> & {
  namespaces?: Namespace[];
};

export const getDefaultServerSideProps = async ({
  locale = SUPPORTED_LOCALES_OBJ.en,
  namespaces = ['common'],
}: ServerSideProps) => {
  // TODO: common -> seo 시트로 변경
  if (!namespaces.includes('common')) {
    namespaces.push('common');
  }
  return {
    props: {
      ...(await serverSideTranslations(locale, namespaces)),
      [THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS]: true,
    },
  };
};

export const getDefaultStaticProps = async ({
  locale = SUPPORTED_LOCALES_OBJ.en,
  namespaces = ['common'],
}: StaticProps) => {
  // TODO: common -> seo 시트로 변경
  if (!namespaces.includes('common')) {
    namespaces.push('common');
  }
  return {
    props: {
      ...(await serverSideTranslations(locale, namespaces)),
      [THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS]: true,
    },
  };
};
