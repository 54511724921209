import { useState } from 'react';
import { createPortal } from 'react-dom';
import { NoticeResponseDto } from '@zep/api';
import { useViewportDevice } from '@zep/hooks';
import { ExclamationmarkSmallTriangleFillIcon, XIcon } from '@zep/icons';
import { NoticeLogger } from '@zep/module/Notice/NoticeLogger';
import { Button } from '@zep/ui_v3';
import { cn } from '@zep/utils';
import { NoticeManager } from './NoticeStorage';
export const TextNotice = (props: NoticeResponseDto) => {
  const [isActive, setActive] = useState(true);
  const {
    id,
    content
  } = props;
  const {
    isMobile
  } = useViewportDevice();
  const handleClose = () => {
    NoticeManager.setNotice(id || '', 'TEXT');
    NoticeLogger.onClickClose(props);
    setActive(false);
  };
  if (!isActive) return null;
  return createPortal(<div datatype={'notice'} className={cn([isMobile ? 'min-w-[calc(100vw-32px)]' : 'min-w-[300px]', 'animate-duration-500 animate-delay-[400ms] animate-ease-in', 'fixed top-[100px] items-center p-sm  flex justify-between', '-translate-x-1/2 left-1/2 z-notice flex max-w-[1000px] gap-xs  rounded-sm bg-[rgba(255,255,255,0.90)]  ', 'backdrop-blur-[2px] shadow-[0px_4px_12px_0px_rgba(28,27,31,0.12),_0px_0px_16px_0px_rgba(28,27,31,0.04)]'])}>
      <div className={'flex shrink items-center gap-xs'}>
        <ExclamationmarkSmallTriangleFillIcon fill={'#F4C620'} className={'flex shrink-0'} size={'md'} />
        <span className={'text-body-lg-semibold text-default'}>
          {content?.title}
        </span>
      </div>
      <Button intent={'neutral'} size={'sm'} className={'px-2xs'} onClick={handleClose} variant={'transparent'}>
        <XIcon fill={'#5D5E69'} />
      </Button>
    </div>, document.body);
};