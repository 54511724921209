import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useBooleanState, usePreservedCallback } from '@toss/react';
import { setHttpClientToken } from '@zep/api/httpClient';
import { GoogleOAuthStorage } from '@zep/shared/google';
import { User } from '@zep/types';
import { combinePathAndQuery } from '@zep/utils';
import { zettaClient } from '@zep/utils/analytics';
import { zepLogger } from '@zep/utils/localLogger';
import { noop } from 'lodash-es';
import { useRouter } from 'next/router';
import { clearStorageUser, getStorageUser, setStorageUser } from './userStorage';
const defaultUserContext: UserContextType = {
  user: null,
  setUser: noop,
  updateUser: noop,
  logOut: () => Promise.resolve(),
  isInitialization: false,
  isAuthenticated: false
};
export const UserContext = createContext<UserContextType>(defaultUserContext);
export const UserProvider = ({
  children
}: PropsWithChildren) => {
  const router = useRouter();
  const [isInitialization, setInitialization] = useBooleanState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const handleUser = (_user: User | null) => {
    if (_user) {
      setHttpClientToken(_user.token);
      // TODO: user를 localStorage에 저장하는 이유가 무엇일까?
      setStorageUser(_user);
      redirectSignupPage(_user);
      setCurrentUser({
        ...currentUser,
        ..._user
      });
      return;
    }
    setCurrentUser(null);
  };
  const logOut = useCallback(async ({
    redirectTo
  }: {
    redirectTo?: string;
  } = {}) => {
    clearStorageUser();
    setCurrentUser(null);
    setHttpClientToken('');
    GoogleOAuthStorage.resetToken();
    if (redirectTo != null) {
      await router.push(redirectTo);
    }
    return Promise.resolve();
  }, [router]);
  const isAuthenticated = useMemo<boolean>(() => {
    if (currentUser) return true;
    if (!isInitialization) return false;
    return !!getStorageUser();
  }, [isInitialization, currentUser]);
  const redirectSignupPage = usePreservedCallback((user: User | null) => {
    if (!user) return;
    const skipRedirectPaths = ['/login', '/sign-up', '/trouble', '/terms', '/policy'];
    if (skipRedirectPaths.some(path => router.pathname.startsWith(path))) {
      return;
    }
    if (!user.registrationStatus) {
      router.push(combinePathAndQuery('/sign-up/type', router.query));
      return;
    }
    if (!user.type || user.type.toLowerCase() === 'unknown') {
      router.push(combinePathAndQuery('/sign-up/type', router.query));
      return;
    }
  });
  const updateUser = usePreservedCallback((_user: Partial<User>) => {
    if (!currentUser) return;
    const user = {
      ...currentUser,
      ..._user
    };
    setStorageUser(user);
    setCurrentUser(user);
  });
  useEffect(() => {
    const user = getStorageUser();
    if (!user) {
      setInitialization();
      return;
    }
    const isExpired = user.expiredAt && new Date(user.expiredAt) < new Date();
    if (isExpired) {
      logOut({
        redirectTo: '/login'
      });
      setInitialization();
      return;
    }
    setCurrentUser(user);
    setInitialization();
    redirectSignupPage(user);
  }, [logOut, redirectSignupPage, router, setInitialization]);
  useEffect(() => {
    if (currentUser) {
      zettaClient.identify(currentUser.email);
      zepLogger.setState({
        IS_LOGIN: true
      });
      Sentry.setUser({
        email: currentUser.email,
        username: currentUser.username,
        isAdmin: currentUser.admin,
        userType: currentUser.type
      });
    } else {
      zepLogger.setState({
        IS_LOGIN: false
      });
    }
  }, [currentUser]);
  return <UserContext.Provider value={{
    isAuthenticated,
    isInitialization,
    user: currentUser,
    setUser: handleUser,
    updateUser,
    logOut
  }} data-sentry-element="unknown" data-sentry-component="UserProvider" data-sentry-source-file="UserContext.tsx">
      {children}
    </UserContext.Provider>;
};
type UserContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
  updateUser(user: Partial<User>): void;
  logOut: (props?: {
    redirectTo?: string;
  }) => Promise<void>;
  isInitialization: boolean;
  isAuthenticated: boolean;
};