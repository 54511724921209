import { ApiTokenStorage } from '@zep/module/auth';
import { getApplicationSessionId, getBrowserSessionId } from '@zep/utils';
import { AxiosRequestConfig } from 'axios';

const apiToken = ApiTokenStorage.get();

export const axiosConfig: AxiosRequestConfig = {
  baseURL: `${process.env.NEXT_PUBLIC_SERVER_BASEURL}/api/`,
  headers: {
    'Content-Type': 'application/json',
    ...(apiToken?.length > 0 && {
      Authorization: `Bearer ${apiToken}`,
    }),
    'X-ZEP-Application-Session-Id': getApplicationSessionId(),
    'X-ZEP-Browser-Session-Id': getBrowserSessionId(),
  },
  timeout: 1000 * 15, // 15초 retry 는 react-query 레벨로 이관
  timeoutErrorMessage: 'API Request Real Timeout ',
  withCredentials: true,
};
