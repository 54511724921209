import { Children, cloneElement, FC, ReactElement, ReactNode } from 'react';
import { EventProperties } from '@zep/utils/analytics';
import { zepLogger } from '@zep/utils/localLogger';
type ClickTrackingProps = {
  eventName?: string;
  eventProperties?: EventProperties;
  children: ReactNode;
};
export const ClickTracking: FC<ClickTrackingProps> = ({
  eventName,
  eventProperties,
  children
}) => {
  const handleClick = (event: MouseEvent) => {
    if (eventName) {
      // 클릭 이벤트 트래킹
      zepLogger.log(eventName, eventProperties ? {
        optionalParams: eventProperties
      } : undefined);
    }

    // 자식 컴포넌트의 기존 onClick 이벤트 호출
    const child = (Children.only(children) as ReactElement);
    const childOnClick = (child?.props as any).onClick;
    if (typeof childOnClick === 'function') {
      childOnClick(event);
    }
  };
  return cloneElement((children as React.ReactElement), {
    onClick: handleClick
  });
};