export const UpdateNews = () => <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="UpdateNews" data-sentry-source-file="UpdateNews.tsx">
    <path d="M13.5 4.08301C12.6264 4.08301 11.8784 4.62067 11.5689 5.38313C8.47899 6.22959 6.20833 9.05779 6.20833 12.4163V17.3093L4.29995 20.1719C4.08685 20.4915 4.06699 20.9025 4.24826 21.2412C4.42953 21.5799 4.78251 21.7913 5.16667 21.7913H21.8333C22.2175 21.7913 22.5705 21.5799 22.7517 21.2412C22.933 20.9025 22.9131 20.4915 22.7001 20.1719L20.7917 17.3093V12.4163C20.7917 9.05779 18.521 6.22959 15.4311 5.38313C15.1216 4.62067 14.3736 4.08301 13.5 4.08301Z" fill="url(#paint0_linear_9467_2904)" data-sentry-element="path" data-sentry-source-file="UpdateNews.tsx" />
    <path d="M10.0857 22.6247C10.499 23.946 11.871 24.9163 13.5 24.9163C15.1289 24.9163 16.501 23.946 16.9144 22.6247H10.0857Z" fill="url(#paint1_linear_9467_2904)" data-sentry-element="path" data-sentry-source-file="UpdateNews.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="UpdateNews.tsx">
      <linearGradient id="paint0_linear_9467_2904" x1="13.5" y1="4.08301" x2="13.5" y2="24.9163" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="UpdateNews.tsx">
        <stop stopColor="#FFB6FF" data-sentry-element="stop" data-sentry-source-file="UpdateNews.tsx" />
        <stop offset="1" stopColor="#F268F5" data-sentry-element="stop" data-sentry-source-file="UpdateNews.tsx" />
      </linearGradient>
      <linearGradient id="paint1_linear_9467_2904" x1="13.5" y1="4.08301" x2="13.5" y2="24.9163" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="UpdateNews.tsx">
        <stop stopColor="#FFB6FF" data-sentry-element="stop" data-sentry-source-file="UpdateNews.tsx" />
        <stop offset="1" stopColor="#F268F5" data-sentry-element="stop" data-sentry-source-file="UpdateNews.tsx" />
      </linearGradient>
    </defs>
  </svg>;