import { ReactElement } from 'react';
import { NoSSR } from '@zep/components';
import { useLocale } from '@zep/hooks';
import { HomeLogo } from '@zep/layout';
import { MobileDrawer } from '@zep/layout/Header/MobileDrawer/MobileDrawer';
import { AuthButton, CreateNewQuizButton } from '@zep/layout/Header/UserManagementButton';
import { LogOptions } from '@zep/utils/logger';
import { useTranslation } from 'next-i18next';
import { FAQ } from './icons/FAQ';
import { Notice } from './icons/Notice';
import { UpdateNews } from './icons/UpdateNews';
import { UserGuide } from './icons/UserGuide';
import { HeaderContainer } from './HeaderContainer';
import { LeftButtons } from './LeftButtons';
import { EnterCodeButton, ExploreButton } from './PageNavigatorButton';
export const filterVisibleButtons = (buttons: GnbButtonType[]) => {
  return buttons.filter(button => !button.isHidden);
};

// subButtons가 있다면 href/target이 불필요합니다.
export interface GnbButtonType {
  label: string;
  icon?: ReactElement;
  href?: string;
  target?: string;
  isHidden?: boolean;
  clickEventname?: string;
  clickEventProperties?: LogOptions['optionalParams'];
  subButtons?: GnbSubButtonType[];
}
export interface GnbSubButtonType extends GnbButtonType {
  href: string;
  subButtons?: never;
}
export function GnbHeader() {
  const {
    t
  } = useTranslation(['common']);
  const {
    locale
  } = useLocale();
  const GNB_BUTTONS: GnbButtonType[] = [{
    label: t('header.explore.button'),
    href: '/public',
    clickEventname: 'gnb_public_click'
  }, {
    label: t('common.header.pricingGuide'),
    href: '/pricing',
    clickEventname: 'gnb_pricing_click'
  }, {
    label: '선생님 후기',
    href: 'https://zep-quiz-teachers-review.super.site/',
    target: '_blank',
    isHidden: locale !== 'ko',
    clickEventname: 'gnb_review_click'
  }, {
    label: t('common.header.customerSupport'),
    clickEventname: 'gnb_customer_support_click',
    subButtons: [{
      label: t('common.header.notice'),
      icon: <Notice />,
      href: t('common.header.noticeLink'),
      target: '_blank',
      clickEventname: 'gnb_notice_click'
    }, {
      label: t('common.header.updateNews'),
      icon: <UpdateNews />,
      href: t('common.header.updateNewsLink'),
      target: '_blank',
      clickEventname: 'gnb_update_news_click'
    }, {
      label: t('common.header.userGuide'),
      icon: <UserGuide />,
      href: t('common.header.guideLink'),
      target: '_blank',
      clickEventname: 'gnb_guide_click'
    }, {
      label: t('home.pricing.frequentlyQuestion'),
      icon: <FAQ />,
      href: t('common.header.faqLink'),
      target: '_blank',
      clickEventname: 'gnb_faq_click'
    }]
  }];
  const MOBILE_GNB_BUTTONS: GnbButtonType[] = [{
    label: t('header.explore.button'),
    href: '/public',
    clickEventname: 'gnb_public_click'
  }, {
    label: t('common.header.pricingGuide'),
    href: '/pricing',
    clickEventname: 'gnb_pricing_click'
  }, {
    label: t('common.header.notice'),
    icon: <Notice />,
    href: t('common.header.noticeLink'),
    target: '_blank',
    clickEventname: 'gnb_notice_click'
  }, {
    label: t('common.header.userGuide'),
    icon: <UserGuide />,
    href: t('common.header.guideLink'),
    target: '_blank',
    clickEventname: 'gnb_guide_click'
  }];
  return <HeaderContainer className="justify-start" data-sentry-element="HeaderContainer" data-sentry-component="GnbHeader" data-sentry-source-file="GnbHeader.tsx">
      <HomeLogo location={'header'} data-sentry-element="HomeLogo" data-sentry-source-file="GnbHeader.tsx" />
      <div className="ml-[24px] hidden items-center gap-2xs overflow-x-auto md:flex [&>*]:shrink-0">
        <LeftButtons buttons={filterVisibleButtons(GNB_BUTTONS)} data-sentry-element="LeftButtons" data-sentry-source-file="GnbHeader.tsx" />
      </div>
      <div className="ml-auto flex shrink-0 items-center gap-2xs">
        <div className={'flex md:hidden'}>
          <ExploreButton.Icon data-sentry-element="unknown" data-sentry-source-file="GnbHeader.tsx" />
        </div>
        <AuthButton data-sentry-element="AuthButton" data-sentry-source-file="GnbHeader.tsx" />
        <div className={'hidden gap-2xs md:flex md:items-center'}>
          <EnterCodeButton type={'normal'} data-sentry-element="EnterCodeButton" data-sentry-source-file="GnbHeader.tsx" />
          <CreateNewQuizButton type={'normal'} data-sentry-element="CreateNewQuizButton" data-sentry-source-file="GnbHeader.tsx" />
        </div>

        <NoSSR fallback={<MobileDrawer.TriggerContent />} data-sentry-element="NoSSR" data-sentry-source-file="GnbHeader.tsx">
          <MobileDrawer buttons={filterVisibleButtons(MOBILE_GNB_BUTTONS)} footerBottomAccessory={<>
                <CreateNewQuizButton type={'drawer'} />
                <EnterCodeButton type={'drawer'} />
              </>} data-sentry-element="MobileDrawer" data-sentry-source-file="GnbHeader.tsx" />
        </NoSSR>
      </div>
    </HeaderContainer>;
}