import { safeLocalStorage } from '@toss/storage';
import { createTypedLocalStorage } from '@toss/storage/typed';
import { ApiTokenStorage } from '@zep/module/auth';
import { User } from '@zep/types';
import { deleteCookie, setCookie } from 'cookies-next';

export const setStorageUser = (_user: Partial<User>) => {
  const user = safeLocalStorage.get('quiz_user');

  const setStorage = (newUser: User) => {
    safeLocalStorage.set('quiz_user', JSON.stringify(newUser));
    ApiTokenStorage.set(newUser.token || '');
  };

  if (!user) {
    setStorage(_user as User);
  } else {
    const newUser = { ...JSON.parse(user), ..._user };
    setStorage(newUser);
  }
  setCookie('session-quiz-zep', true, {
    secure: process.env.NODE_ENV === 'production',
  });
};

export const clearStorageUser = () => {
  safeLocalStorage.remove('quiz_user');
  ApiTokenStorage.remove();
  deleteCookie('session-quiz-zep');
};

export const getStorageUser = () => {
  try {
    if (!ApiTokenStorage.get()) return null;
    const user = createTypedLocalStorage<User | null>('quiz_user', {
      initialValue: null,
    });

    return user.get();
  } catch (e) {
    console.error('parse error user storage');
    return null;
  }
};
