import { HTMLAttributeAnchorTarget, PropsWithChildren, ReactNode, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { SupportedLocale } from '@zep/consts/localize';
import { useUser } from '@zep/lib/auth';
import { Button } from '@zep/ui';
import { LogOptions } from '@zep/utils/logger';
import Link from 'next/link';
import { GnbButtonType } from '..';
import { GnbButton } from './GnbButton';
import { LanguageSwitch } from './LanguageSwitch';
export type GnbButtonInfo = {
  link: string;
  text: string;
  track: string;
  alias: string;
  icon?: string | ReactNode;
  hideLocales: SupportedLocale[];
  target: HTMLAttributeAnchorTarget;
  eventName?: string;
  eventProperties?: LogOptions['optionalParams'];
};
export const LeftButtons = ({
  buttons
}: {
  buttons: GnbButtonType[];
}) => {
  const {
    user
  } = useUser();
  const [isAdminButtonHidden, setIsAdminButtonHidden] = useState(false);
  return <>
      {buttons.map(button => <GnbButton key={button.label} button={button} />)}
      {user?.admin && !isAdminButtonHidden && <div className="flex items-center">
          <GnbLeftButton key={'admin'}>
            <Link href={'/admin'} target={'_blank'} prefetch={false}>
              어드민
            </Link>
          </GnbLeftButton>
          <LanguageSwitch />
          <Button size={'sm'} transParented intent={'neutral'} onClick={() => setIsAdminButtonHidden(true)}>
            (어드민 숨기기)
          </Button>
          <Button size={'sm'} transParented intent={'neutral'} onClick={async () => {
        const name = `Test Sentry Error In ${process.env.NEXT_PUBLIC_STAGE}`;
        if (!window.confirm('센트리 에러 테스트 하시겠습니까?')) {
          return;
        }
        await Sentry.startSpan({
          name,
          op: 'test'
        }, async () => {
          const res = await fetch('/api/sentry-example-api');
          if (!res.ok) {
            throw new Error(name);
          }
        });
      }}>
            센트리 에러
          </Button>
        </div>}
    </>;
};
const GnbLeftButton = (props: PropsWithChildren) => <Button size={'sm'} transParented intent={'neutral'} asChild data-sentry-element="Button" data-sentry-component="GnbLeftButton" data-sentry-source-file="LeftButtons.tsx">
    {props.children}
  </Button>;