import { withScope } from '@sentry/nextjs';
import { HttpStatusCodeEnum, ZepQuizApiError } from '@zep/api/httpClient';
import Axios, { AxiosError } from 'axios';

const isApiErrorInErrorCodes = (error: unknown, httpCodes: number[]) => {
  if (error instanceof ZepQuizApiError) {
    return httpCodes.includes(error.httpStatus);
  }

  if (Axios.isAxiosError(error)) {
    return httpCodes.includes(error.response?.status || 200);
  }
};

export const isIgnoreError = (_error: unknown) => {
  if (Axios.isCancel(_error)) {
    return true;
  }

  return isApiErrorInErrorCodes(_error, [
    HttpStatusCodeEnum.UNAUTHORIZED_401,
    HttpStatusCodeEnum.FORBIDDEN_403,
  ]);
};

// 404 에러, 400 에러는 무시
export const isIgnoreRetryError = (_error: unknown) => {
  return isApiErrorInErrorCodes(_error, [
    HttpStatusCodeEnum.UNAUTHORIZED_401,
    HttpStatusCodeEnum.FORBIDDEN_403,
    HttpStatusCodeEnum.BAD_REQUEST_400,
    HttpStatusCodeEnum.NOT_FOUND_404,
    HttpStatusCodeEnum.INTERNAL_SERVER_ERROR_500,
  ]);
};

export const setNetworkErrorLogger = (error: AxiosError) => {
  if (isIgnoreError(error)) return;
  let isNetworkError = false;

  if (Axios.isAxiosError(error)) {
    if (!error.response && error.message === 'Network Error') {
      console.warn(
        '네트워크 연결이 불안정하거나 서버에 접근할 수 없습니다.',
        error,
      );
      isNetworkError = true;
    } else if (!error.response) {
      console.warn('네트워크 문제 (서버 응답 없음):', error);
    } else if (error.code === 'ECONNABORTED') {
      isNetworkError = true;
      console.warn('요청이 타임아웃되었습니다.', error);
    }

    let type = 'client';

    if (error.response?.config.url) {
      const url = error.response?.config.url;
      if (url.includes('cdn-cms.zep.us')) {
        type = 'cms';
      }
      if (url.includes('api.zep.us')) {
        type = 'api-server';
      }
    }

    if (isNetworkError) {
      withScope(scope => {
        scope.setLevel(type === 'client' ? 'warning' : 'error');
        scope.setFingerprint(['network-error', type]);
        scope.captureException(error);
      });
    }
  }
};

export const LONG_REQUEST_TIMEOUT = 5 * 60 * 1000;
