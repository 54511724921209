import { useViewportDeviceInternal } from './useViewportDeviceInternal';

/**
 * @deprecated 디자인시스템 breakpoint가 적용된 useViewPort를 사용해 주세요.
 */
export const useViewportDevice = () => {
  const { isLaptop, isTablet, isMobile, isMobileSmall } =
    useViewportDeviceInternal();

  const device: DeviceType = (() => {
    switch (true) {
      case isTablet:
        return 'tablet';
      case isMobileSmall:
        return 'mobileSmall';
      case isMobile:
        return 'mobile';
      case isLaptop:
        return 'laptop';
      default:
        return 'desktop';
    }
  })();

  const isPortableDevice = ['mobile', 'mobileSmall', 'tablet'].includes(device);

  return {
    device,
    isPortableDevice,
    isLaptop: device === 'laptop',
    isTablet: device === 'tablet',
    isMobile: device === 'mobile' || device === 'mobileSmall',
    isPortraitMobile: device === 'mobileSmall',
  };
};

export type DeviceType =
  | 'desktop'
  | 'laptop'
  | 'tablet'
  | 'mobile'
  | 'mobileSmall';
