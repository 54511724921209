import { NoSSR } from '@zep/components';
import { HeaderContainer } from '@zep/layout/Header/HeaderContainer';
import { MobileDrawer } from '@zep/layout/Header/MobileDrawer/MobileDrawer';
import { AuthButton, CreateNewQuizButton } from '@zep/layout/Header/UserManagementButton';
import { useTranslation } from 'next-i18next';
import { filterVisibleButtons, GnbButtonType } from '..';
import { LeftButtons } from './LeftButtons';
import { ExploreButton } from './PageNavigatorButton';
export function GnbHeaderForGoogleClassroom() {
  const {
    t
  } = useTranslation(['common']);
  const GNB_BUTTONS: GnbButtonType[] = [{
    label: t('googleClassroom.pricingTextgnbHeader.pricingText'),
    href: '/me/quiz',
    clickEventname: 'GC_gnb_tab_click',
    clickEventProperties: {
      name: 'myList'
    }
  }, {
    label: t('header.explore.button'),
    href: '/public',
    clickEventname: 'GC_gnb_tab_click',
    clickEventProperties: {
      name: 'public'
    }
  }];
  const MOBILE_GNB_BUTTONS: GnbButtonType[] = [
  // MobileDrawer에 내 퀴즈 버튼이 이미 존재하므로 제외합니다.
  {
    label: t('header.explore.button'),
    href: '/public',
    clickEventname: 'GC_gnb_tab_click',
    clickEventProperties: {
      name: 'public'
    }
  }];
  return <HeaderContainer data-sentry-element="HeaderContainer" data-sentry-component="GnbHeaderForGoogleClassroom" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx">
      <div className="flex items-center gap-[24px]">
        <div className={'gap hidden gap-sm md:flex'}>
          <LeftButtons buttons={filterVisibleButtons(GNB_BUTTONS)} data-sentry-element="LeftButtons" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx" />
        </div>
      </div>
      <div className="flex items-center gap-2xs">
        <div className={'flex md:hidden'}>
          <ExploreButton.Icon data-sentry-element="unknown" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx" />
        </div>
        <AuthButton data-sentry-element="AuthButton" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx" />
        <div className={'hidden gap-2xs md:flex md:items-center'}>
          <CreateNewQuizButton type={'normal'} data-sentry-element="CreateNewQuizButton" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx" />
        </div>

        <NoSSR fallback={<MobileDrawer.TriggerContent />} data-sentry-element="NoSSR" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx">
          <MobileDrawer logo={<div />} buttons={filterVisibleButtons(MOBILE_GNB_BUTTONS)} footerBottomAccessory={<CreateNewQuizButton type={'drawer'} />} data-sentry-element="MobileDrawer" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx" />
        </NoSSR>
      </div>
    </HeaderContainer>;
}