import { useState } from 'react';
import { useLocale } from '@zep/hooks';
import { CommonTranslationKeys } from '@zep/types/translation';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuTrigger } from '@zep/ui';
import { Button } from '@zep/ui_v3';
import { cn } from '@zep/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
export const LanguageSwitch = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const {
    t
  } = useTranslation(['common']);
  const {
    locales
  } = useLocale();
  return <DropdownMenu open={isOpen} onOpenChange={setIsOpen} modal={false} data-sentry-element="DropdownMenu" data-sentry-component="LanguageSwitch" data-sentry-source-file="LanguageSwitch.tsx">
      <DropdownMenuTrigger className="hidden h-[40px] max-w-[140px] items-center overflow-hidden whitespace-nowrap rounded-[8px] py-[10px] pl-xs pr-2xs text-[14px] focus:outline-none md:flex" tabIndex={-1} data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="LanguageSwitch.tsx">
        <Button size={'sm'} variant={'transparent'} intent={'neutral'} asChild data-sentry-element="Button" data-sentry-source-file="LanguageSwitch.tsx">
          <span>🌐</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="shadow-[0_4px_8px_2px_rgba(28, 27, 31, 0.12)] min-w-[148px] rounded-[8px] px-[8px] py-3xs text-[13px]" data-sentry-element="DropdownMenuContent" data-sentry-source-file="LanguageSwitch.tsx">
        <DropdownMenuGroup data-sentry-element="DropdownMenuGroup" data-sentry-source-file="LanguageSwitch.tsx">
          {locales.map(locale => <DropdownMenuItem key={locale} onClick={() => {
          router.push(router.asPath, undefined, {
            locale
          });
        }} className={cn('cursor-pointer p-xs text-body-sm font-medium')}>
              {(t(`common.language.${locale}`) as CommonTranslationKeys)} (
              {locale.toUpperCase()})
            </DropdownMenuItem>)}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>;
};