import { safeLocalStorage } from '@toss/storage';
import { isServer } from '@toss/utils';

const API_TOKEN_STORAGE_KEY = 'token';

export const ApiTokenStorage = {
  get: () => {
    if (isServer()) {
      return '';
    }
    return safeLocalStorage.get(API_TOKEN_STORAGE_KEY) ?? '';
  },
  set: (token: string) => {
    safeLocalStorage.set(API_TOKEN_STORAGE_KEY, token);
  },
  remove: () => {
    safeLocalStorage.remove(API_TOKEN_STORAGE_KEY);
  },
};
