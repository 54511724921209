import { safeLocalStorage } from '@toss/storage';

interface NoticeItem {
  id: string;
  expirationTime: number;
  type: 'MODAL' | 'TEXT';
}

interface NoticeStorage {
  notices: Record<string, NoticeItem>;
  lastCleanup: number;
}

export class NoticeManager {
  private static readonly STORAGE_KEY = 'notice-manager';
  private static readonly CLEANUP_INTERVAL = 24 * 60 * 60 * 1000; // 24시간마다 정리

  private static getStorage(): NoticeStorage {
    try {
      const data = safeLocalStorage.get(this.STORAGE_KEY);
      return data ? JSON.parse(data) : { notices: {}, lastCleanup: Date.now() };
    } catch {
      return { notices: {}, lastCleanup: Date.now() };
    }
  }

  private static setStorage(storage: NoticeStorage): void {
    safeLocalStorage.set(this.STORAGE_KEY, JSON.stringify(storage));
  }

  private static cleanupExpiredNotices(): void {
    const storage = this.getStorage();
    const now = Date.now();

    // 24시간마다 만료된 항목 정리
    if (now - storage.lastCleanup > this.CLEANUP_INTERVAL) {
      const notices = Object.entries(storage.notices).reduce(
        (acc, [id, notice]) => {
          if (notice.expirationTime > now) {
            acc[id] = notice;
          }
          return acc;
        },
        {} as Record<string, NoticeItem>,
      );

      this.setStorage({
        notices,
        lastCleanup: now,
      });
    }
  }

  static setNotice(id: string, type: 'MODAL' | 'TEXT'): void {
    this.cleanupExpiredNotices();
    const storage = this.getStorage();

    const expirationTime =
      Date.now() +
      (type === 'MODAL'
        ? 24 * 60 * 60 * 1000 // 24시간
        : 10 * 60 * 1000); // 10분

    storage.notices[id] = {
      id,
      type,
      expirationTime,
    };

    this.setStorage(storage);
  }

  static isNoticeVisible(id: string): boolean {
    this.cleanupExpiredNotices();
    const storage = this.getStorage();
    const notice = storage.notices[id];

    if (!notice) return true;
    return Date.now() > notice.expirationTime;
  }
}
